import { add, arrowDown, business, calendar, clipboard, close, closeCircle, create, exit, eye, eyeOff, menu, notifications, personAdd, refresh, search, time, trash, warning, wifi } from 'ionicons/icons';
export const importIcons = {
    refresh,
    time,
    clipboard,
    warning,
    menu,
    wifi,
    notifications,
    arrowDown,
    closeCircle,
    create,
    exit,
    business,
    close,
    add,
    trash,
    eye,
    eyeOff,
    search,
    personAdd,
    calendar
} 